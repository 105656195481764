import Navbar from "./NavMenu";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Check,
  Star,
  Zap,
  X,
  Clock,
  MapPin,
  Bell,
  Users,
  ThumbsUp,
  AlertCircle
} from "lucide-react";
import React, { useState, useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Footer from "./Footer";

const Subscriptions = () => {
  const promiseKey = process.env.REACT_APP_STRIPE_PROMISE_KEY;
  const stripePromise = loadStripe(promiseKey);
  const [userSubscription, setUserSubscription] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const navigate = useNavigate();

  const features = {
    free: [
      <>
        <span className="font-bold">No Credit Card Required</span>
      </>,
      "1 rental search preference",
      "3 latest listings per day",
      "1 daily email digest",
      "24-hour notification delay",
      "Basic rental listing details"
    ],
    basic: [
      "3 search preferences for different areas",
      "20 latest rental listings",
      "3 email alerts per day",
      "2-hour notification delay",
      "Quick-view property details",
      "Priority listing access"
    ],
    pro: [
      "✨ Unlimited search preferences",
      "⚡ Instant notifications (15-min max)",
      "📨 Unlimited daily alerts",
      "🔍 Detailed property information",
      "⭐ Priority email support",
      "🏃‍♂️ Be first to view properties",
    ]
  };

  // Testimonials data
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Young Professional",
      image: "/user.png",
      text: "Found my dream flat in central London within 2 days of upgrading to Pro. The instant alerts made all the difference!",
      plan: "Pro Plan User"
    },
    {
      name: "Mark Davies",
      role: "Student",
      image: "/user.png",
      text: "The Basic plan was perfect for my house hunt. Got notifications before properties appeared on major websites.",
      plan: "Basic Plan User"
    },
    {
      name: "Emma Thompson",
      role: "Remote Worker",
      image: "/user.png",
      text: "Being able to set multiple area preferences helped me find the perfect work-from-home space.",
      plan: "Pro Plan User"
    }
  ];

  // Statistics
  const statistics = [
    {
      value: "15min",
      label: "Maximum alert delay for Pro users",
      icon: Clock
    },
    {
      value: "3x",
      label: "Daily updates for Basic users",
      icon: Bell
    },
    {
      value: "24hr",
      label: "Free tier notification delay",
      icon: AlertCircle
    }
  ];

  const handleSubscribe = async (tier) => {
    const token = localStorage.getItem("token");
    const stripe = await stripePromise;
    const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-checkout-session`,
        {
          tier: tier,
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/cancel`
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
    );

    const sessionId = response.data;

    // If it's a free subscription, handle success directly
    if (sessionId.startsWith("free_subscription_")) {
      // Redirect to success page or show success message
      window.location.href = `/success`;
      return;
    }

    // For paid subscriptions, redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const handleSubscriptionClick = (tier) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setSelectedTier(tier);
      setShowAuthModal(true);
      return;
    }
    handleSubscribe(tier);
  };

  const fetchUserSubscription = async () => {
    const token = localStorage.getItem("token");
    if (!token) return; // Don't try to fetch if no token

    try {
      const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );
      setUserSubscription(response.data);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };

  // Add useEffect to fetch subscription on component mount
  useEffect(() => {
    fetchUserSubscription();
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Check if the URL has a hash
    if (location.hash === '#faq') {
      // Find the FAQ section
      const faqSection = document.getElementById('faq');
      if (faqSection) {
        // Add a slight delay to ensure the page is fully loaded
        setTimeout(() => {
          faqSection.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  return (
      <>
        <Helmet>
          <title>Dwellio Plans - Choose Your Perfect Rental Alert Package</title>
          <meta name="description" content="Compare Dwellio's subscription plans - Free, Basic, and Pro. Get rental alerts from 15 minutes to 24 hours. Choose the perfect plan for your property search needs." />
        </Helmet>
        <Navbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
            {/* Hero Section */}
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Never Miss Your Perfect Rental Again
              </h1>
              <p className="text-xl text-gray-600">
                In today's fast-moving rental market, being first matters. Get instant alerts for new rentals that match
                your preferences and beat the competition.
              </p>
            </div>

            {/* Value Proposition Banner */}
            <div className="bg-blue-50 rounded-2xl p-8 mb-16">
              <div className="grid md:grid-cols-3 gap-8">
                <div className="flex items-center space-x-4">
                  <div className="bg-blue-100 rounded-full p-3">
                    <Zap className="h-6 w-6 text-blue-600"/>
                  </div>
                  <div>
                    <h3 className="font-semibold">Fast Alerts</h3>
                    <p className="text-sm text-gray-600">Be first to know</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="bg-blue-100 rounded-full p-3">
                    <MapPin className="h-6 w-6 text-blue-600"/>
                  </div>
                  <div>
                    <h3 className="font-semibold">Multiple Areas</h3>
                    <p className="text-sm text-gray-600">Search everywhere</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="bg-blue-100 rounded-full p-3">
                    <Users className="h-6 w-6 text-blue-600"/>
                  </div>
                  <div>
                    <h3 className="font-semibold">Priority Access</h3>
                    <p className="text-sm text-gray-600">Beat other renters</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Pricing Cards */}
            <div id="pricing" className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
              {/* Free Tier */}
              <div className={`rounded-2xl ${
                  userSubscription?.status === "Free"
                      ? "border-2 border-green-500 relative"
                      : "border border-gray-200"
              } bg-white shadow-sm p-8`}>
                {/* ... [Keep your existing Free tier card structure but update content] */}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold text-gray-900">Free</h3>
                  <p className="mt-2 text-gray-600">
                    Test drive our rental alerts
                  </p>
                  <p className="mt-4">
                    <span className="text-4xl font-bold text-gray-900">£0</span>
                    <span className="text-gray-600">/month</span>
                  </p>
                </div>
                <ul className="space-y-4 mb-8">
                  {features.free.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-3"/>
                        <span className="text-gray-600">{feature}</span>
                      </li>
                  ))}
                </ul>
                <button
                    className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                        userSubscription?.status === "Free"
                            ? "bg-green-100 text-green-700 cursor-default"
                            : "bg-gray-100 hover:bg-gray-200 text-gray-900"
                    }`}
                    onClick={() => handleSubscriptionClick("Free")}
                    disabled={userSubscription?.status === "Free"}
                >
                  {userSubscription?.status === "Free"
                      ? "Current Plan"
                      : "Get Started"}
                </button>
              </div>

              {/* Premium Tier */}
              <div className={`rounded-2xl ${
                  userSubscription?.status === "Basic"
                      ? "border-2 border-green-500 relative"
                      : "border border-gray-200"
              } bg-white shadow-sm p-8`}>
                {userSubscription?.status === "Basic" && (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Current Plan
                  </span>
                    </div>
                )}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold text-gray-900">Basic</h3>
                  <p className="mt-2 text-gray-600">
                    For active rental searchers
                  </p>
                  <p className="mt-4">
                    <span className="text-4xl font-bold text-gray-900">£6.99</span>
                    <span className="text-gray-600">/month</span>
                  </p>
                  <p className="mt-2 text-sm text-blue-600">
                    Most popular for short-term searches
                  </p>
                </div>
                <ul className="space-y-4 mb-8">
                  {features.basic.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-3"/>
                        <span className="text-gray-600">{feature}</span>
                      </li>
                  ))}
                </ul>
                <button
                    className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                        userSubscription?.status === "Basic"
                            ? "bg-green-100 text-green-700 cursor-default"
                            : "bg-gray-900 hover:bg-gray-800 text-white"
                    }`}
                    onClick={() => handleSubscriptionClick("basic")}
                    disabled={userSubscription?.status === "Basic"}
                >
                  {userSubscription?.status === "Basic"
                      ? "Current Plan"
                      : "Subscribe Now"}
                </button>
              </div>

              {/* Pro Tier (Highlighted) */}
              <div className={`rounded-2xl ${
                  userSubscription?.status === "Pro"
                      ? "border-2 border-green-500 relative"
                      : "border-2 border-blue-500"
              } bg-white shadow-lg p-8 relative`}>
                {userSubscription?.status === "Pro" ? (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Current Plan
                  </span>
                    </div>
                ) : (
                    <div className="absolute -top-5 left-1/2 transform -translate-x-1/2">
                  <span className="bg-blue-500 text-white px-4 py-2 rounded-full text-sm font-medium flex items-center">
                    <Star className="h-4 w-4 mr-1"/>
                    Most Popular
                  </span>
                    </div>
                )}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold text-blue-600">Pro</h3>
                  <p className="mt-2 text-gray-600">
                    For urgent rental needs
                  </p>
                  <p className="mt-4">
                    <span className="text-4xl font-bold text-gray-900">£12.99</span>
                    <span className="text-gray-600">/month</span>
                  </p>
                  <p className="mt-2 text-sm text-blue-600">
                    Perfect for busy professionals
                  </p>
                </div>
                <ul className="space-y-4 mb-8">
                  {features.pro.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-blue-500 mr-3"/>
                        <span className="text-gray-600">{feature}</span>
                      </li>
                  ))}
                </ul>
                <button
                    className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                        userSubscription?.status === "Pro"
                            ? "bg-green-100 text-green-700 cursor-default"
                            : "bg-blue-600 hover:bg-blue-700 text-white"
                    }`}
                    onClick={() => handleSubscriptionClick("pro")}
                    disabled={userSubscription?.status === "Pro"}
                >
                  {userSubscription?.status === "Pro"
                      ? "Current Plan"
                      : userSubscription?.trialAvailable  // Access trialAvailable directly
                          ? "Start Free Trial"
                          : "Subscribe Now"}
                </button>
              </div>
            </div>

            {/* Add this after pricing cards */}
            <p className="text-center text-sm text-gray-500 mt-8">
              * Alert times are approximate and depend on when properties are listed. We strive to deliver notifications
              as quickly as possible.
            </p>

            <div className="mt-24 bg-blue-50 rounded-2xl p-8">
              <div className="max-w-3xl mx-auto text-center">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                  Why Speed Matters in Today's Rental Market
                </h3>
                <p className="text-gray-600 mb-8">
                  In competitive areas, desirable properties can be let within hours of listing. Our Pro users are
                  typically among the first to contact agents and view properties, giving them a significant advantage.
                </p>
                <div className="grid md:grid-cols-3 gap-6">
                  {statistics.map((stat, index) => (
                      <div key={index} className="bg-white p-6 rounded-lg">
                        <div className="flex justify-center mb-4">
                          <stat.icon className="h-8 w-8 text-blue-600"/>
                        </div>
                        <h4 className="font-bold text-blue-600 text-3xl mb-2">{stat.value}</h4>
                        <p className="text-sm text-gray-600">{stat.label}</p>
                      </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Add this before testimonials */}
            <div className="mt-24 bg-gray-50 rounded-2xl p-8">
              <h3 className="text-2xl font-bold text-center text-gray-900 mb-8">
                Time Saved with Dwellio
              </h3>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-white p-6 rounded-lg">
                  <h4 className="font-semibold text-lg mb-4">Without Dwellio</h4>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">
                      <X className="h-5 w-5 text-red-500 mr-3" />
                      Manually checking multiple websites
                    </li>
                    <li className="flex items-center">
                      <X className="h-5 w-5 text-red-500 mr-3" />
                      Missing out on properties already let
                    </li>
                    <li className="flex items-center">
                      <X className="h-5 w-5 text-red-500 mr-3" />
                      Delayed responses to new listings
                    </li>
                  </ul>
                </div>
                <div className="bg-white p-6 rounded-lg">
                  <h4 className="font-semibold text-lg mb-4">With Dwellio Pro</h4>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-3" />
                      Instant alerts for new properties
                    </li>
                    <li className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-3" />
                      Be among first to contact agents
                    </li>
                    <li className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-3" />
                      Never miss relevant listings
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-24">
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
                What Our Users Say
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="bg-white p-6 rounded-2xl shadow-sm border border-gray-100">
                      <div className="flex items-center mb-4">
                        <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            className="h-12 w-12 rounded-full mr-4"
                        />
                        <div>
                          <h4 className="font-semibold text-gray-900">{testimonial.name}</h4>
                          <p className="text-sm text-gray-600">{testimonial.role}</p>
                        </div>
                      </div>
                      <p className="text-gray-600 mb-4">{testimonial.text}</p>
                      <div className="flex items-center text-blue-600 text-sm">
                        <ThumbsUp className="h-4 w-4 mr-2"/>
                        {testimonial.plan}
                      </div>
                    </div>
                ))}
              </div>
            </div>

            {/* Features Section */}
            <div className="mt-24 max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
                Why Choose Our Pro Plan?
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center">
                  <div className="mx-auto w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <Zap className="h-6 w-6 text-blue-600"/>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Instant Alerts</h3>
                  <p className="text-gray-600">
                    Be the first to know about new properties with near real-time notifications
                  </p>
                </div>
                <div className="text-center">
                  <div className="mx-auto w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <MapPin className="h-6 w-6 text-blue-600"/>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">
                    Multiple Areas
                  </h3>
                  <p className="text-gray-600">
                    Search across different locations simultaneously to maximize your options
                  </p>
                </div>
                <div className="text-center">
                  <div className="mx-auto w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <Star className="h-6 w-6 text-blue-600"/>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Priority Support</h3>
                  <p className="text-gray-600">
                    Get help when you need it with our dedicated support team
                  </p>
                </div>
              </div>
            </div>

            {showAuthModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                  <div className="bg-white rounded-2xl max-w-md w-full p-8 relative">
                    <button
                        onClick={() => setShowAuthModal(false)}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                    >
                      <X className="h-6 w-6"/>
                    </button>

                    <div className="text-center mb-8">
                      <h2 className="text-2xl font-bold text-gray-900 mb-4">
                        Create an Account
                      </h2>
                      <p className="text-gray-600">
                        To start your {selectedTier} subscription, please create an account or sign in.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <button
                          onClick={() => {
                            localStorage.setItem('pendingSubscription', selectedTier);
                            navigate('/register');
                          }}
                          className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                      >
                        Create Account
                      </button>

                      <div className="text-center">
                        <span className="text-gray-500">Already have an account?</span>
                      </div>

                      <button
                          onClick={() => {
                            localStorage.setItem('pendingSubscription', selectedTier);
                            navigate('/login');
                          }}
                          className="w-full py-3 px-6 rounded-lg border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium transition-colors"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </div>
            )}

            {/* FAQ Section */}
            <div id="faq" className="mt-24 max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
                Frequently Asked Questions
              </h2>
              <div className="space-y-8">
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    How quickly will I receive rental alerts?
                  </h3>
                  <p className="text-gray-600">
                    Alert speed depends on your plan - Free users receive a daily digest, Basic users get 3 updates per
                    day, and Pro users receive alerts within 15 minutes of new listings.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    Can I search in multiple areas?
                  </h3>
                  <p className="text-gray-600">
                    Yes! Free users get 1 search area, Basic users get 3 areas, and Pro users can set unlimited search
                    areas to maximize their chances.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    Can I cancel anytime?
                  </h3>
                  <p className="text-gray-600">
                    Yes, you can cancel your subscription at any time. You'll continue to have access until the end of
                    your billing period.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    How do the email alerts work?
                  </h3>
                  <p className="text-gray-600">
                    You'll receive email notifications with property details and direct links to listings. Pro users get
                    the fastest alerts to help them be first to contact agents.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
  );
};

export default Subscriptions;