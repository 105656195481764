import React, { useState } from 'react';
import Navbar from './NavMenu';
import { Mail, Clock, AlertCircle, CheckCircle2 } from 'lucide-react';
import axios from "axios";
import { Helmet } from 'react-helmet-async';
import Footer from "./Footer";
import {useNavigate} from "react-router-dom";

const Contact = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/contact/submit`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                setSubmitStatus('success');
                setFormData({ name: '', email: '', subject: '', message: '' });
            }
        } catch (error) {
            setSubmitStatus('error');
            console.error('Error submitting contact form:', error);
        }
    };

    const handleFAQClick = (e) => {
        e.preventDefault();
        // Navigate to subscriptions page with FAQ hash
        navigate('/subscriptions#faq');
    };

    return (
        <>
            <Helmet>
                <title>Contact Dwellio - Get Help With Your Property Search</title>
                <meta name="description" content="Need help with your rental property search? Contact Dwellio's support team. We're here to help you find your perfect rental property faster." />
            </Helmet>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    {/* Header */}
                    <div className="text-center max-w-3xl mx-auto mb-16">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Get in Touch
                        </h1>
                        <p className="text-xl text-gray-600">
                            Have questions about our service? We're here to help you find your perfect rental property.
                        </p>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-16">
                        {/* Contact Information */}
                        <div>
                            <div className="bg-white rounded-2xl shadow-sm p-8 mb-8">
                                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                                    Contact Information
                                </h2>
                                <div className="space-y-6">
                                    <div className="flex items-start">
                                        <Mail className="h-6 w-6 text-blue-600 mt-1 mr-4" />
                                        <div>
                                            <h3 className="font-semibold text-gray-900">Email Us</h3>
                                            <p className="text-gray-600">support@dwellio.com</p>
                                            <p className="text-sm text-gray-500">We aim to respond within 24 hours</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* FAQ Quick Links */}
                            <div className="bg-gray-50 rounded-2xl p-8">
                                <h2 className="text-xl font-bold text-gray-900 mb-4">
                                    Common Questions
                                </h2>
                                <ul className="space-y-4">
                                    <li>
                                        <a
                                            href="/Subscriptions#faq"
                                            onClick={handleFAQClick}
                                            className="text-blue-600 hover:text-blue-700 flex items-center"
                                        >
                                            <Clock className="h-5 w-5 mr-2"/>
                                            How quickly will I get alerts?
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/Subscriptions#faq"
                                            onClick={handleFAQClick}
                                            className="text-blue-600 hover:text-blue-700 flex items-center"
                                        >
                                            <Clock className="h-5 w-5 mr-2"/>
                                            Can I cancel my subscription?
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/Subscriptions#faq"
                                            onClick={handleFAQClick}
                                            className="text-blue-600 hover:text-blue-700 flex items-center"
                                        >
                                            <Clock className="h-5 w-5 mr-2"/>
                                            How many areas can I search?
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* Contact Form */}
                        <div className="bg-white rounded-2xl shadow-sm p-8">
                            <h2 className="text-2xl font-bold text-gray-900 mb-6">
                                Send Us a Message
                            </h2>
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                                        Your Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your name"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your email"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                                        Subject
                                    </label>
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="What is this about?"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                                        Message
                                    </label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows="4"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="How can we help you?"
                                    />
                                </div>

                                {submitStatus && (
                                    <div className={`p-4 rounded-lg ${
                                        submitStatus === 'success'
                                            ? 'bg-green-50 text-green-800'
                                            : 'bg-red-50 text-red-800'
                                    }`}>
                                        <div className="flex items-center">
                                            {submitStatus === 'success' ? (
                                                <>
                                                    <CheckCircle2 className="h-5 w-5 mr-2" />
                                                    Message sent successfully! We'll get back to you soon.
                                                </>
                                            ) : (
                                                <>
                                                    <AlertCircle className="h-5 w-5 mr-2" />
                                                    There was an error sending your message. Please try again.
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;