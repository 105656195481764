import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Navbar from '../NavMenu';
import { Helmet } from 'react-helmet-async';
import { authService } from '../Services/AuthService';
import { useAuth } from '../Context/AuthProvider';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Footer from '../Footer';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isResendingEmail, setIsResendingEmail] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setIsAuthenticated } = useAuth();

    const [showSuccessToast, setShowSuccessToast] = useState(
        location.state?.registered || false
    );

    const redirectTo = location.state?.redirectTo;
    const pendingTier = location.state?.tier;

    const handleResendVerification = async () => {
        setIsResendingEmail(true);
        try {
            await authService.resendVerification(email);
            setError("A new verification email has been sent to your inbox.");
        } catch (err) {
            setError("Failed to resend verification email. Please try again later.");
        } finally {
            setIsResendingEmail(false);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await authService.login(email, password);

            localStorage.setItem('token', response.token);
            localStorage.setItem('userEmail', email);
            setIsAuthenticated(true);

            if (redirectTo) {
                navigate(redirectTo, {
                    state: { tier: pendingTier }
                });
            } else {
                const pendingSubscription = localStorage.getItem('pendingSubscription');
                if (pendingSubscription) {
                    navigate('/subscription-checkout', {
                        state: { tier: pendingSubscription }
                    });
                    localStorage.removeItem('pendingSubscription');
                } else {
                    navigate('/dashboard');
                }
            }
        } catch (err) {
            if (err.message === "Please confirm your email address before logging in.") {
                setError(
                    <div className="space-y-4">
                        <p>Please verify your email address before logging in.</p>
                        <button
                            onClick={handleResendVerification}
                            disabled={isResendingEmail}
                            className="text-blue-600 hover:text-blue-700 text-sm font-medium"
                        >
                            {isResendingEmail ? 'Sending...' : 'Resend verification email'}
                        </button>
                    </div>
                );
            } else if (err.status === 401) {
                setError("Invalid email or password");
            } else {
                setError("An unexpected error occurred. Please try again later.");
            }
        }
    };

    return (
        <>
            <Navbar />
            <Helmet>
                <title>Sign In to Dwellio - Access Your Property Alerts</title>
                <meta
                    name="description"
                    content="Sign in to your Dwellio account to manage your property alerts, view matched properties, and update your rental preferences."
                />
            </Helmet>
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    {/* Header Section */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Welcome Back
                        </h1>
                        <p className="text-xl text-gray-600">
                            Sign in to access your property alerts and preferences
                        </p>
                    </div>

                    {/* Login Form */}
                    <div className="max-w-md mx-auto">
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8">
                            <form onSubmit={handleLogin} className="space-y-6">
                                <div className="space-y-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your email"
                                    />
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your password"
                                    />
                                </div>

                                {error && (
                                    <div className="p-4 rounded-lg bg-red-50 text-red-600">
                                        <p>{error}</p>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Sign In
                                </button>
                            </form>

                            <div className="mt-6 text-center">
                                <p className="text-gray-600">
                                    Forgot your password?{' '}
                                    <Link to="/forgot-password"
                                          className="text-blue-600 hover:text-blue-700 font-medium">
                                        Reset Password
                                    </Link>
                                </p>
                            </div>

                            <div className="mt-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300"/>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">Or continue with</span>
                                    </div>
                                </div>

                                <div className="mt-6 flex justify-center">
                                    <div className="w-full max-w-[320px] flex justify-center">
                                        <GoogleLogin
                                            onSuccess={async (credentialResponse) => {
                                                try {
                                                    const decodedToken = jwtDecode(credentialResponse.credential);
                                                    const firstName = decodedToken.given_name;

                                                    const response = await authService.googleSignIn(credentialResponse.credential);
                                                    localStorage.setItem('token', response.token);
                                                    localStorage.setItem('userEmail', jwtDecode(credentialResponse.credential).email);
                                                    localStorage.setItem('firstName', firstName);
                                                    setIsAuthenticated(true);

                                                    if (redirectTo) {
                                                        navigate(redirectTo, {state: {tier: pendingTier}});
                                                    } else {
                                                        navigate('/dashboard');
                                                    }
                                                } catch (err) {
                                                    setError("Failed to sign in with Google. Please try again.");
                                                }
                                            }}
                                            onError={() => {
                                                setError("Failed to sign in with Google. Please try again.");
                                            }}
                                            useOneTap
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 text-center">
                                <p className="text-gray-600">
                                    Don't have an account?{' '}
                                    <Link to="/register" className="text-blue-600 hover:text-blue-700 font-medium">
                                        Create one now
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Success Toast */}
                {showSuccessToast && (
                    <div className="fixed bottom-4 right-4 z-50">
                        <div className="bg-green-500 text-white px-6 py-4 rounded-lg shadow-lg">
                            <div className="flex items-center">
                                <svg className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M5 13l4 4L19 7"/>
                                </svg>
                                <p>Account created successfully! Please sign in.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Login;